import React from 'react';
import { useEffect } from 'react';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import { ProfileData } from '~data/profileData';
import PracticeAreasList from '~2-components/PracticeAreasList/PracticeAreasList';

const practiceAreaListEg = `import PracticeAreasList from '~2-components/PracticeAreasList/PracticeAreasList';

<PracticeAreasList
    practiceAreas=[
        {
            title: '', // Required, practice area page name
            url: '' //Required, practice area page url
        },
        // as many as you need
    ]
/>
`;

const SgPracticeAreaList = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>Practice Area List</h1>
                    <p>Show a nicely formatted list of practice areas on the Profile pages.</p>
                    <p><strong>NOTE:</strong> There's no content type in the CMS, this is a purely front end component. CMS data comes from the Profile content type.</p>

                    <h3>How to use</h3>
                    <CodeBlock
                        code={practiceAreaListEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <PracticeAreasList
                        practiceAreas={ProfileData.practiceAreas}
                    />
                </div>
            </section>
        </SgLayout>
    );
};

export default SgPracticeAreaList;
