import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const PracticeAreasList = ({ practiceAreas }) => {
    return (
        <div className="practice-areas-list">
            <h6>Practice areas:</h6>
            <ul>
                {practiceAreas && practiceAreas.map(area => {
                    const title = area.elements ? area.elements.title.value : area.title;
                    const url = area.elements ? area.elements.page_slug.value : area.url;
                    return (
                        <li key={title}>
                            <Link to={`/${url}`} >{title}</Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

PracticeAreasList.propTypes = {
    practiceAreas: PropTypes.array.isRequired
};

export default PracticeAreasList;
