import formatPhoneNumber from '~utils/formatPhoneNumber';

export const ProfileData = {
    fullName: `Bree Knoester`,
    position: `Managing Partner`,
    phone: {
        txt: `(03) 9321 9879`,
        url: `tel:${formatPhoneNumber('(03) 9321 9879)')}`
    },
    email: {
        txt: `Email`,
        url: `mailto:bree.knoester@alil.com.au`
    },
    hasImage: false,
    heroImage: null,
    breadcrumbs: [
        {
            title: 'Home',
            isActive: true
        }
    ],
    aboutText: ``,
    practiceAreas: [
        {
            elements: {
                title: {
                    value: 'Asbestos'
                },
                page_slug:{
                    value: '/asbestos'
                }
            }
        },
        {
            elements: {
                title: {
                    value: 'Silica Dust'
                },
                page_slug:{
                    value: '/silica-dust'
                }
            }
        },
    ]
};
